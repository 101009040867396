//调用产业链的相关接口
import $http from './index'
//获取产业分类
export const getIndustryClassList=()=>{
  return $http.get(`/api/IndustryChain/GetIndustryClassList`);
}
//获取产业分类信息
export const getIndustryClassInfo=(classId)=>{
  return $http.get(`/api/IndustryChain/GetIndustryClassInfo?classId=${classId}`);
}
//获取产业分类的产业链数量
export const getChainNumOfIndustryClass=(classId)=>{
  return $http.get(`/api/IndustryChain/GetChainNumOfIndustryClass?classId=${classId}`);
}
//获取产业链信息
export const getIndustryChainInfo=(chainId)=>{
  return $http.get(`/api/IndustryChain/GetIndustryChainInfo?chainId=${chainId}`);
}
//获取产业链列表
export const getIndustryChainInfoList=(data)=>{
  return $http.post(`/api/IndustryChain/GetIndustryChainInfoList`,data);
}
//获取产业链列表及产品服务、企业统计
export const getIndustryChainList=(data)=>{
  return $http.post(`/api/IndustryChain/GetIndustryChainList`,data);
}
//获取产业链的产品或服务
export const getServicesOfIndustryChain=(chainId)=>{
  return $http.get(`/api/IndustryChain/GetServicesOfIndustryChain?chainId=${chainId}`);
}
//获取产业链中产品或服务的企业
export const getEpListOfService=(serviceId,regionCode,pageSize,pageIndex,orderFiled,order)=>{
  console.log(order);
  return $http.post(`/api/IndustryChain/GetEpListOfService?serviceId=${serviceId}&regionCode=${regionCode}&pageSize=${pageSize}&pageIndex=${pageIndex}&orderFiled=${orderFiled}&order=${order}`);
}
//获取产业链中产品或服务的专家列表
export const getPersonListOfService=(serviceId,pageSize,pageIndex)=>{
  return $http.post(`/api/IndustryChain/GetPersonListOfService?serviceId=${serviceId}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
}