<template>
  <Header ></Header>
  <div id="mapContainer"></div>
  <!-- 地图地区Tooltip -->
  <div ref='mapMarker' class="tipMarker top"></div>
  <div v-if="isRight">
    <!-- 当前时间地区指标值 -->
    <div style="padding:20px 100px 0px 20px;position:relative;">
      <div id='itemSelect' style="display: flex;justify-content: center;min-height:88px;">
        <div v-for="item in curRegionItemData.filter(t=>t.value!='-')" :key="item.id"  class="col-d" :class="item.isSel?'selcol':''" @click="selItemFun(item.id)">
          <span class="name"  :title="item.name+(item?.unit?'('+item?.unit+')':'')">
            <i class="iconfont" :class="item.icon"></i> 
            {{item.name+(item?.unit?'('+item?.unit+')':'')}}
          </span>
          <p>
            <CountTo  :end='item.value' :autoPlay="true" :default='item.value.toString().split(".").length>1?2:0' :duration='3000' />
            <span class="fontsize14" style="padding-left:2px;">{{item.isForecast}}</span>  
          </p> 
          <div>
            <span  class="item-speed" :class="{'down':item.speed<0,'up':item.speed>=0}" >{{item.id=="20010507"?item.itemIncrement:item.speed}}% <i class="iconfont" :class='{"ec-icon-top":item.speed>=0,"ec-icon-bottom":item.speed<0}'></i> </span>
            <span  style="float:right;"> No:{{item.ranking}}</span>
          </div>
        </div>
      </div>
      <!-- <el-row :gutter="20" id='itemSelect'>
        <el-col :span="3" v-for="item in curRegionItemData.filter(t=>t.value!='-')" :key="item.id">
          <div  class="col-d" :class="item.isSel?'selcol':''" @click="selItemFun(item.id)">
            <span class="name"  :title="item.name+(item?.unit?'('+item?.unit+')':'')">
              <i class="iconfont" :class="item.icon"></i> 
              {{item.name+(item?.unit?'('+item?.unit+')':'')}}
            </span>
            <p>
              <CountTo  :end='item.value' :autoPlay="true" :default='item.value.toString().split(".").length>1?2:0' :duration='3000' />
              <span class="fontsize14" style="padding-left:2px;">{{item.isForecast}}</span>  
            </p> 
            <div>
              <span v-if='item.speed && item.speed!="-" && item.id!="20010507"' class="item-speed" :class="{'down':item.speed<0,'up':item.speed>=0}" >{{item.speed}}% <i class="iconfont" :class='{"ec-icon-top":item.speed>=0,"ec-icon-bottom":item.speed<0}'></i> </span>
              <span  style="float: right;"> <i class='iconfont ec-icon-no'></i>:{{item.ranking}}</span>
            </div>
          </div>
        </el-col>
      </el-row> -->
      <!-- 时间列表 -->
      <div style="position:absolute;top:20px;right:20px;">
        <el-dropdown @command="selectDate" :max-height='200'>
          <div style="width: 60px;">
            <span class="time-title">年份</span>
            <div class="time-content">
              {{ new Date(currentDate).getFullYear()}}<i class="iconfont ec-icon-caret-bottom"></i>
            </div>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-for="item in dateList" :key="item.date" :command="item.date" >{{new Date(item.date).getFullYear()}}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown >
      </div>
    </div>
    <div style="position: absolute;left:20px;top:200px;">
      <div id='ecoVitality'>
          <span v-if='!isShowEcoVitality' @click="isShowEcoVitality=true;selCard=2;" class="eco-fixed" ><i class="iconfont ec-icon-fill" style="font-size: 1.8rem;"></i></span> 
          <transition name="el-zoom-in-left">
            <div v-show='isShowEcoVitality'  class="box no-padding">
              <span  class="txt padding10"><i class="iconfont ec-icon-fill mr-5"></i>地区经济活力</span>
              <span v-if='regionScore' style="position: absolute;right:40px;top:10px;font-weight: 700;">
                <span style="color:#ff9900;font-size:12px;margin-right:10px;">{{regionScore}}</span>
                <span style="font-size:12px;">{{regionOrder}}</span>
              </span>
              <i  class="iconfont ec-icon-s-fold chain-fold" @click="isShowEcoVitality=false"></i>
              <div style="display: flex;" >
                <div>
                  <el-tooltip
                    effect="light"
                    content="地区简介"
                    placement="right-start"
                  >
                    <span @click="selCardFun(1)" class="card" :class="{'active':selCard==1}"><i class="iconfont ec-icon-location"></i></span>
                  </el-tooltip>
                  <br>
                  <el-tooltip
                    effect="light"
                    content="经济活力"
                    placement="right-start"
                  >
                    <span @click="selCardFun(2)" class="card" :class="{'active':selCard==2}"><i class="iconfont ec-icon-jingjijianshe"></i></span>
                  </el-tooltip>
                  <br>
                  <el-tooltip
                    effect="light"
                    content="经济评价"
                    placement="right-start"
                  >
                    <span @click="selCardFun(3)" class="card" :class="{'active':selCard==3}"><i class="iconfont ec-icon-gdp"></i></span>
                  </el-tooltip>
                  <br>
                  <el-tooltip
                    effect="light"
                    content="财政评价"
                    placement="right-start"
                  >
                  <span @click="selCardFun(4)" class="card" :class="{'active':selCard==4}"><i class="iconfont ec-icon-finance"></i></span>
                  </el-tooltip>
                  <br>
                  <el-tooltip
                    effect="light"
                    content="民生评价"
                    placement="right-start"
                  >
                  <span @click="selCardFun(5)" class="card" :class="{'active':selCard==5}"><i class="iconfont ec-icon-livelihood"></i></span>
                  </el-tooltip>
                  <br>
                  <el-tooltip
                    effect="light"
                    content="营商环境"
                    placement="right-start"
                  >
                  <span @click="selCardFun(6)" class="card" :class="{'active':selCard==6}"><i class="iconfont ec-icon-yingshang"></i></span>
                  </el-tooltip>
                  <br>
                  <el-tooltip
                    effect="light"
                    content="消费市场"
                    placement="right-start"
                  >
                  <span @click="selCardFun(7)" class="card" :class="{'active':selCard==7}"><i class="iconfont ec-icon-xiaofei"></i></span>
                  </el-tooltip>
                </div>
                <div v-show="selCard==1" class="card-info">
                      <el-image style="width:100%;height:100%;border-radius: 4px;" :src="`https://data.chinaecono.com/images/areapic/${regionCode}.jpg`" fit="cover" /> 
                      <div style="position: absolute;top:0;color:#fff;height:100%;width: 100%;">
                        <div  class="pl-10">
                          <el-popover
                            placement="bottom-start"
                            title=""
                            :width="280"
                            trigger="hover"
                            :content="regionDesc"
                          >
                            <template #reference>
                              <span style="display: inline-block;font-size:1.4em;font-weight:bold;padding-right:10px;margin:20px 0;">
                              {{regionName}}
                              </span>
                            </template>
                          </el-popover>
                          <div>
                            <el-tag size="small"  class="mx-1" v-for='item in regionAttribute' :key="item">{{item}}</el-tag>
                          </div>
                        </div>
                        <!-- <div style="padding:0 10px;">
                          <span>{{regionDesc}}</span>
                        </div> -->
                      </div>
                </div>
                <div v-show="selCard==2"  class="card-info">
                  <div style="position: absolute;left: 10px;">
                    <h3 style="margin:0">经济活力</h3>
                  </div>
                  <div id='chart_2' style="height:100%;width:100%;"></div>
                </div>
                <div v-show="selCard>2"  class="card-info">
                  <div style="margin-bottom:10px;">
                    <span style="font-size: 1.17em;font-weight: bold;padding-right: 10px;">{{ecoVitality_itemName}}</span>
                  </div>
                
                  <el-table class="ecovitality_table" 
                    :data="regionGlobalAnalysisData"
                    border
                    :span-method="arraySpanMethod"
                    :row-class-name="ecovitalityTableRowClassName"
                    size='small'
                    header-row-class-name="ecovitality-tb-header"
                    style="width: 100%;">
                    <el-table-column prop="itemName" label="指标项" />
                    <el-table-column prop="weight"  label="权重" width="46"/>
                    <el-table-column prop="sourceValue" label="指标值" width="105"/>
                    <el-table-column prop="itemValue" label="得分" width="53"/>
                  </el-table>
                </div>
              </div>
            </div>
          </transition>
          <!-- <div style="height:50%;width:100%;">
            <el-table
              :data="regionGlobalAnalysisData"
              :span-method="objectSpanMethod"
              border
              style="width: 100%;"
            >
              <el-table-column prop="prevItemName" label="" width="100"  />
              <el-table-column prop="itemName" label="指标项" />
              <el-table-column prop="weight" label="权重" width="50"/>
              <el-table-column prop="sourceVal" label="指标项值" width="70"/>
              <el-table-column prop="itemValue" label="具体得分" width="70"/>
            </el-table>
          </div> -->
      </div>
      <div id='industrychain'  style="margin-top:20px;position:relative;">
        <span v-if='!isShowIndustryChain' @click="isShowIndustryChain=true" class="eco-fixed" ><i class="iconfont ec-icon-relation" style="font-size: 1.8rem;"></i></span> 
          <transition name="el-zoom-in-left">
            <div v-if='isShowIndustryChain'  class="box" >
              <span  class="txt"><i class="iconfont ec-icon-relation mr-5"></i>地区重点产业</span>
              <span class="chain-num">共<strong style="color:rgb(0,47,176);">{{industryChainNum}}</strong>条</span>
              <i  class="iconfont ec-icon-s-fold chain-fold" @click="isShowIndustryChain=false"></i>
              <div :style="{'max-height':(windowHeight-(isShowEcoVitality?660:390) )+'px'}" style="width:380px;overflow-y: auto;" class="scrollbar">
                <el-tag v-for="chain in industryChainList" :key="chain.id" size='small' class="mx-2" 
                  :class="{'pointer':chain.isShow && chain.isShow==1}" :type="chain.isShow && chain.isShow==1?'':'info'" 
                  :effect="chain.isShow && chain.isShow==1?'dark':'light'" @click='selChainFun(chain)'>
                    {{ chain.name }}
                </el-tag>
              </div>
              <!-- <el-table :data="industryChainList" :height="windowHeight-660" size='small' style="width:380px;" @row-click='selChainFun'>
                <el-table-column prop="industryClassName" label="分类"  />
                <el-table-column prop="name" label="产业">
                  <template #default="scope">
                    <span :class="{'txt-color':scope.row.isShow==1,'text-gray':scope.row.isShow==0}">{{scope.row.name}}</span>
                  </template>
                </el-table-column>
              </el-table> -->
            </div>
          </transition>
        <!-- <el-scrollbar style="height:calc(100% - 40px);">
          <div class="chain-tab" v-for="item in industryChainList" :key="item.classId">
            <el-popover placement="right" :width="400" trigger="hover" :show-arrow='false'>
              <template #reference>
                <div class="chain-tab-item">
                  <div class="chain-tab-item-title">{{item.className}}</div>
                  <div class="chain-tab-item-subtilte">
                    <a v-for="chain in item.mainList" :key="chain.id" @click.prevent="selChainFun(chain.id)" :title="chain.name">{{chain.name}}</a>
                  </div>
                  <i class="iconfont ec-icon-arrow-right-bold chain-tab-item-arrow"></i>
                </div>
              </template>
              <div class="scrollbar" style="max-height:200px;overflow: auto;">
                <div  v-for='child in item.children' :key="child.classId">
                  <div class="chain-tab-main-title" >{{child.className}}</div>
                  <div class="chain-tab-main-list">
                    <a v-for='chain in child.chainList' :key="chain.id" class="chain-tab-main-list-item"  @click.prevent="selChainFun(chain.id)" :title="chain.name">{{chain.name}}</a>
                  </div>
                </div>
              </div>
            </el-popover>
          </div>
        </el-scrollbar> -->
      </div>
    </div>
    <div id='regionSore' class="box" style="width:360px;position: absolute;right: 20px;top: 200px;bottom: 90px;">
      <span  class="txt"><i class="iconfont ec-icon-ranking"></i> {{curRegionItemData.find(t=>t.isSel)?.name}}排名</span>
      <el-table  :data="regionSortList" stripe height='95%' style="width: 100%" size='small' :row-class-name="tableRowClassName" @row-click='regionSpeedListRowClick'>
        <el-table-column type="index" label="排名&nbsp;|&nbsp;地区名称" width="170" >
            <template #default="scope">
              <!-- <span :class="{'sort-index':scope.$index<9}">{{ scope.$index + 1}}</span>  -->
              <span class="ranking-top" :style='{"background": scope.row.sortOrder>5?"":"#f56c6c"}'   >{{ scope.row.sortOrder}}</span>
              <span>{{scope.row.regionName}}</span>
            </template>
        </el-table-column>
        <!-- <el-table-column prop="regionName" label="地区"  /> -->
        <el-table-column prop="itemValue" label="实际值">
          <template #default="scope">
            {{ scope.row.itemValue?scope.row.itemValue:'-' }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    
    <!-- <div  class="box" style="width:30%;position: absolute;right: 20px;top: 196px;">
        <span class="txt">{{curRegionItemData.find(t=>t.isSel)?.name}}十年增速变化排名</span>
        <el-link :underline="false" :href='`/#/econo-dev?regionCode=${regionCode}`' type="primary" style="position: absolute; right: 10px;">更多></el-link>
        <el-table  :data="regionSpeedList" stripe  style="width:100%"  :row-class-name="tableRowClassName"  @row-click='regionSpeedListRowClick'>
          <el-table-column type="index" label="排名&nbsp;|&nbsp;地区名称" width="220" >
              <template #default="scope">
                <!- - <span :class="{'sort-index':scope.$index<9}">{{ scope.$index + 1}}</span>  - ->
                <span class="ranking-top" :style='{"background": scope.row.ranking>5?"":"#f56c6c"}' >{{ scope.row.ranking}}</span>
                <span>{{scope.row.regionName}}</span>
              </template>
          </el-table-column>
           <el-table-column prop="itemValue" label="实际值"  />
          <el-table-column prop="speed" label="增速">
            <template #default="scope">
              {{ scope.row.speed?scope.row.speed.toFixed(2)+'%':'-' }}
            </template>
          </el-table-column>
        </el-table>
      <!- - <div class="box" style="height:calc(40% - 20px);">
        <span class="txt">{{selRegionName?selRegionName:'地区'}}{{curRegionItemData.find(t=>t.isSel)?.name}}历年变化</span>
        <div id='chart_1' style="height:100%;width:100%;"></div>
      </div> - ->
    </div> -->
    <!-- 经济舆情 -->
    <div class="card-f" id='showNews'>
      <div  class="para-title" >
        <span>
          <i class="iconfont ec-icon-group-fill mr-5" style="font-size:24px;"></i>
          <!-- <svg class="icon para-title-i" aria-hidden="true" style="font-size:24px;">
            <use xlink:href="#ec-icon-zichan"></use>
          </svg> -->
          <span class="para-title-text">经济舆情</span>
        </span>
      </div>
      <eco-article-scroll :regionCode='regionCode'></eco-article-scroll>
    </div>
    
  
    <!-- 地图色阶 v-if='colorLevel.length>0'-->
    <ul class="ul-cl" v-if='false'>
        <li v-for="(item,index) in colorLevel" :key="index"><span class="span-cl" :style="{background:colors[index]}"></span><span>{{index==0?">"+item:colorLevel[index-1]+"~"+item}}</span></li>
        <li v-if="colorLevel?.length>1"><span class="span-cl" :style="{background:colors[5]}"></span><span>&lt;{{ colorLevel[4]}}</span></li>
    </ul>
    <!-- 地区历年趋势 -->
    <el-dialog custom-class="dialog-c" destroy-on-close v-model="isVisible" @opened="getRegionItemListFun" :title="(selRegionName?selRegionName:'地区')+curRegionItemData.find(t=>t.isSel)?.name+'历年趋势'">
        <div id='chart_1' style="height:300px;width:100%;" v-loading="loadingShow" element-loading-text="正在加载中..."></div>
    </el-dialog>
  </div>
</template>

<script>
import AMap from 'AMap'
import AMapUI from 'AMapUI'
import {onMounted, reactive, ref,onBeforeUnmount, computed, watch, nextTick} from 'vue'
import {getCurRegionItemData,getNextReginCurItemData,getItemList,getRegionEcoVitality,getRegionItemSort,/*getRegionSpeedSort,*/getRegionItemLine} from '@/http/ecoApi'
import {getRegionInfo,addUserLog,getUserRightFun} from '@/http/basicsApi.js'
import {getIndustryChainInfoList} from '@/http/industryChain.js'
import * as echarts from 'echarts'
import {PrefixInteger,parseTimeOfCycle,parseTime} from '@/utils/index'
import Header from '@/components/Header'
import EcoArticleScroll from '@/components/EcoArticleScroll'
//import glo_loading from '../utils/loading' //loading组件的简单封装
import CountTo from '@/components/countTo/CountTo.vue'
import store  from '../store'
import { useRoute, useRouter } from 'vue-router'
import {homeIntro} from '@/utils/intro-set'
import{setCookie,getCookie,hexToRGBA} from '@/utils/index.js'
//import { ElLoading } from 'element-plus'
import { start, close } from '@/utils/nprogress';
export default {
  name: 'Home',
  components:{
    Header,
    EcoArticleScroll,
    CountTo
  },
   setup(){

    let route=useRoute();
    let router=useRouter();
    let isRight=ref(false);
    const windowHeight =ref(document.documentElement.clientHeight);// 可视区的高度
    //const header = ref();
    let regionName=ref('');//地区名称
    let regionDesc=ref('');//地区描述
    let regionAttribute=ref([]);//地区属性
    let regionScore=ref('');//经济活力得分
    let regionOrder=ref('');//经济活力排名
    let isShowEcoVitality=ref(true);//地区经济活力卡片的显示隐藏
    let isShowIndustryChain=ref(true);//地区重点产业卡片的显示隐藏
    //判断是否有权限访问此页面
    getUserRightFun(route.name,0).then(res=>{
      isRight.value=res;
      if(isRight.value){
        nextTick(()=>{
          if(!getCookie("intro_home")){
            homeIntro
            // 点击结束按钮后执行的事件
            .oncomplete(() => {
              //设置cookie，有效期30天。页面的引导页完成后，30内不再显示引导页
              setCookie("intro_home",1,30)
            }).start();
          }
        })
        
        watch(
          ()=>regionCode.value,
          async (newVal)=> {
            //console.log('监听全局变量regionCode',newVal);
            if(newVal){
              echartsAry.value.forEach(element => {
                if (element) {
                  element.clear(); //清空图表
                  element.dispose(); //释放图表组件
                  element = null;
                }
              });
              //regionName.value=header.value.regionName;
              //加载等待
              //glo_loading.loadingShow();
              //加载地图数据
              nextTick(()=>{
                initAMap();
              })
              isShowEcoVitality.value=true;
              isShowIndustryChain.value=true;
              selCard.value=2;
              regionScore.value='';
              regionOrder.value=''
              //地区信息
              await getRegionInfo(newVal).then((res)=>{
                //console.log(res);
                if(res.code==20000){
                    regionName.value=res.data.name;
                    regionLevel.value=res.data.level;
                    regionAttribute.value=res.data.attribute?res.data.attribute.split('、'):[];
                    regionDesc.value=res.data.desc;
                  }
              });
              //地区经济活力
              getRegionScoreFun()
              //产业链列表
              industryChainNum.value=0;
              industryChainList.value=[];
              getIndustryChainListFun();
              //加载当前时间地区经济指标数据
              await getCurRegionItemDataFun();
              //地图显示
              await getLevelReginCurItemDataFun();
              
              //当前地区同级排名
              await getRegionItemSortFun();
              //当前地区同级地区增速排名
              //await getRegionSpeedSortFun();
              //glo_loading.loadingHide();
              
              //记录用户操作日志
              addUserLog(router.currentRoute.value.name,router.currentRoute.value.fullPath,'加载',JSON.stringify({itemId:curRegionItemData.find(t=> t.isSel==true).id, date:currentDate.value,regionCode:regionCode.value}));
            }
          },
          {
            immediate:true,// 这个属性是重点啦
            //deep:true // 深度监听的参数
          }
        )
      }
      else{
        nextTick(()=>{
        initAMap();
      })
      }
      
    })
    
    //加载高德地图
    let map;
    let districtExplorer=null;
    const mapMarker=ref();
    let echartsAry=ref([]);//echart对象
    //当前时间
    let currentDate=ref(new Date().getMonth()>2?(new Date().getFullYear()-1)+"-01-01":(new Date().getFullYear()-2)+"-01-01");
    let dateList=reactive([]);//时间列表
    for (var i = 0, len = 10; i < len; i++) {
      dateList.push({date:new Date(currentDate.value).getFullYear()-i+"-01-01"});            
    }
    //淄博市项目平台调整   2023-04-17
    //currentDate.value='2021-01-01'
    //选中地区信息
    let regionCode=computed(()=> store.state.curRegionCode);
    console.log(regionCode.value);
    let regionLevel=ref();//地区等级

    //高德地图初始化
    const initAMap=()=>{
        map=new AMap.Map("mapContainer",{ // eslint-disable-line no-unused-vars
           zoom: 5, //级别
          center: [116.397428, 39.90923], //中心点坐标
          //viewMode:'3D',                                   //使用3D视图
          //mapStyle: "amap://styles/light", //设置地图的显示样式mapStyle: 'amap://styles/whitesmoke', //设置地图的显示样式
        })
        
        AMapUI.load(['ui/geo/DistrictExplorer', 'lib/$'], function (DistrictExplorer, $) {// eslint-disable-line no-unused-vars
            //创建一个实例
            districtExplorer = window.districtExplorer = new DistrictExplorer({
                eventSupport: true, //打开事件支持
                map: map
            });
            //鼠标hover提示内容
            var tipMarker = new AMap.Marker({
                content:mapMarker.value,
                offset: new AMap.Pixel(0, 0),
                bubble: true
            });
            //根据Hover状态设置相关样式
            function toggleHoverFeature(feature, isHover, position) {
                tipMarker.setMap(isHover ? map : null);

                if (!feature) {
                    return;
                }
                var props = feature.properties;
                
                if (isHover) {
                    let item=curRegionItemData.filter(t=>{return t.isSel==true})[0];
                    let itemName=item.name;
                    let itemUnit=item.unit;
                    let valStr="-";
                    let speed='-';
                    let ranking='-';
                    let filterArray = curChildRegionItemData.value.filter((e)=> { return e.regionCode ==props.adcode; });
                    if(filterArray.length > 0 && filterArray[0]['itemValue']!=null){
                      valStr=filterArray[0]['itemValue'].toFixed(2)+(filterArray[0]['isForecast']==true?'E':'')+itemUnit;
                      speed=filterArray[0]['speed']?filterArray[0]['speed']:'-';
                      ranking=filterArray[0]['ranking']?filterArray[0]['ranking']:'-';
                    }
                    mapMarker.value.innerHTML=props.name + '(' + new Date(currentDate.value).getFullYear() + '年'  + ')'
                              +'<br/>'+itemName+'：'+ valStr
                              +'<br/>增速：'+(speed!='-'?speed+'%':speed)
                              +'<br/>排名：'+(ranking!='-'?'第'+ranking+'名':ranking);
                    //更新位置
                    tipMarker.setPosition(position || props.center);
                }
                //更新相关多边形的样式
                
                var polys = districtExplorer.findFeaturePolygonsByAdcode(props.adcode);
                for (var i = 0, len = polys.length; i < len; i++) {
                    polys[i].setOptions({
                        fillOpacity: isHover ? 1 : 0.7
                    });
                }
            }


            //监听feature的hover事件
            districtExplorer.on('featureMouseout featureMouseover', function (e, feature) {
                toggleHoverFeature(feature, e.type === 'featureMouseover',
                    e.originalEvent ? e.originalEvent.lnglat : null);
            });

            //监听鼠标在feature上滑动
            districtExplorer.on('featureMousemove', function (e, feature) {// eslint-disable-line no-unused-vars
                //更新提示位置
                tipMarker.setPosition(e.originalEvent.lnglat);
            });
            /*
            //feature被点击
            districtExplorer.on('featureClick', function (e, feature) {
                var props = feature.properties;
                //如果存在子节点
                if (props.childrenNum > 0 ) {
                    //切换聚焦区域
                    let regionGrade=props.acroutes.length;
                    selectRegion(props.adcode, props.name, props.acroutes[regionGrade-1],regionGrade);
                }
            });

            //外部区域被点击
            districtExplorer.on('outsideClick', function (e) {

                districtExplorer.locatePosition(e.originalEvent.lnglat, function (error, routeFeatures) {

                    if (routeFeatures && routeFeatures.length > 1) {
                        //切换到省级区域
                        selectRegion(routeFeatures[1].properties.adcode, routeFeatures[1].properties.name, '0','1');

                    } else {
                        //切换到全国
                        selectRegion('100000', '全国','','0');
                    }

                }, {
                    levelLimit: 2
                });
            });
            */
            //地图显示
            // if(isRight.value){
            //   getLevelReginCurItemDataFun();
            // }
        });
    };
    
    
    //地图加载区域
    function loadAreaNode(adcodes, callback) {
      //console.log(adcodes);
      districtExplorer.loadMultiAreaNodes(adcodes, function (error, areaNodes) {
        if (error) {
          if (callback) {
              callback(error);
          }
          //console.error(error);
          return;
        }
        if (callback) {
            callback(null, areaNodes);
        }
        //设置定位节点，支持鼠标位置识别
        //注意节点的顺序，前面的高优先级
        districtExplorer.setAreaNodesForLocating(areaNodes);
        //清除已有的绘制内容
        districtExplorer.clearFeaturePolygons();
         for (var i = 0, len = areaNodes.length; i < len; i++) {
                renderAreaPolygons(areaNodes[i]);
            }
        
        //更新地图视野
        //map.setFitView(districtExplorer.getAllFeaturePolygons());
        let areaNode=areaNodes.filter(((e)=> { return e.adcode ==regionCode.value; }))[0];
        //map.setBounds(areaNode.getBounds(), null, null, true);
        //console.log( areaNode.getIdealZoom());
        map.setCenter(areaNode._data.geoData.parent.properties.center); //设置地图中心点
        console.log(regionLevel.value);
        if(regionLevel.value==1){
          map.setZoom(areaNode.getIdealZoom()-0.2);
        }
        else{
          map.setZoom(areaNode.getIdealZoom()-1);
        }
        
      });

    }
 
    function renderAreaPolygons(areaNode) {

      //绘制子区域
      districtExplorer.renderSubFeatures(areaNode, function (feature, i) {// eslint-disable-line no-unused-vars
        let {fillColor}= getFillColorFun(feature.properties.adcode);
        return {
            cursor: 'default',
            bubble: true,
            strokeColor:'#002FA7',//feature.properties.adcode==regionCode.value? '#e6a23c':fillColor?fillColor:"#3366cc", //线颜色 fillColor?fillColor:"#3366cc", //线颜色
            strokeOpacity: 1, //线透明度
            strokeWeight: 1, //线宽
            fillColor: fillColor, //填充色
            fillOpacity:0.7, //填充透明度
          };
        });
        //绘制父区域
        districtExplorer.renderParentFeature(areaNode, {
            cursor: 'default',
            bubble: true,
            strokeColor: '#002FA7', //线颜色
            strokeOpacity:1, //线透明度
            strokeWeight: areaNode.adcode==regionCode.value? 2:0, //线宽
            fillColor: null, //填充色
            fillOpacity: 0, //填充透明度
        }
      );
    }
    //获取地图地区的透明度
    const getFillColorFun=(adcode)=>{
      let fillColor=null;
      
        //把数值分为六段色阶（4%，11%，23%，42%，70%）
        var filterArray = curChildRegionItemData.value.filter((e)=> { return e.regionCode ==adcode; });
        if(filterArray && filterArray.length>0 && filterArray[0]['itemValue'] && colorLevel.value.length>0){
          let val=filterArray[0]['itemValue'];
          if(val>=colorLevel.value[0]){
            fillColor=colors[0];
          }
          else if(val>=colorLevel.value[1]){
            fillColor=colors[1];
          }
          else if(val>=colorLevel.value[2]){
            fillColor=colors[2];
          }
          else if(val>=colorLevel.value[3]){
            fillColor=colors[3];
          }
          else if(val>=colorLevel.value[4]){
            fillColor=colors[4]
          }
          else{
            fillColor=colors[5]
          }
        }  
        return{fillColor}




      // var filterArray = curChildRegionItemData.value.filter((e)=> { return e.regionCode ==adcode; });
      // let fillOpacityVal=0;
      // if(filterArray && filterArray.length>0){
      //   if(filterArray[0]['itemValue'] ){//指标项有值
      //      fillOpacityVal= (curRegionChildNum.value-filterArray[0]['ranking'])/curRegionChildNum.value;
      //      fillOpacityVal= fillOpacityVal<0.1?0.1:fillOpacityVal;//透明度最小为0.1，没有值时为0
      //   }
      //   else if(!filterArray[0]['itemValue']){
      //     fillOpacityVal=0;
      //   }
      // }
      //return {fillOpacityVal,filterArray};
    }
    //获取地图数据（等级地区指标项数据）
    const curChildRegionItemData=ref(null);
    let mapRegonArr=ref([]);//地图的地区
    let colorLevel=ref([]);//色阶数组
    const colors =reactive(['rgb(207,1,73)','rgb(233,90,22)','rgb(255,211,32)','rgb(171,203,32)','rgb(0,151,99)','rgb(3,151,239)']);
        
    const getLevelReginCurItemDataFun=async()=>{
      colorLevel.value=[];//清空
      const params={
        regionCode:regionCode.value,
        date:currentDate.value,
        itemId:curRegionItemData.find(t=>t.isSel==true)?.id,
        isForecast:true
      }
      //console.log(params);
      const result=await getNextReginCurItemData(params)
      //console.log(result);
      if(result.code==20000){
        curChildRegionItemData.value=result.data.dataList;
        //色阶
        //如果没有传入值，Math.max()(返回传入值中的最大值)将返回 -Infinity，Math.min()(返回传入值中的最小值)将返回Infinity。
        let maxVal=Math.max.apply(Math, result.data.dataList.map(item=>item.itemValue).filter(item=>item))//指标项值最大值
        let minVal=Math.min.apply(Math, result.data.dataList.map(item=>item.itemValue).filter(item=>item))//指标项值最小值
        //把数值分为六段色阶
        //把数值分为六段色阶
        if(!Object.is(-Infinity, maxVal) || !Object.is(Infinity, minVal)){
          let val=maxVal-minVal;
          if(val==0){//只有一个值或者都是一个数值
             colorLevel.value.push(val);
          }
          else{
            colorLevel.value.push(Math.floor(val*0.75+minVal));//Math.floor(x)向下舍入
            colorLevel.value.push(Math.floor(val*0.50+minVal));
            colorLevel.value.push(Math.floor(val*0.30+minVal));
            colorLevel.value.push(Math.floor(val*0.15+minVal));
            colorLevel.value.push(Math.floor(val*0.05+minVal));
          }
        }
        // if(curRegionItemData.filter(t=>{return t.isSel==true})[0].id=='20002724'){//人均GDP
        //   colorLevel.value=[260000,130000,95290,52000,26000];
        // }
        //地区父级+港澳台地区
        mapRegonArr.value=[regionCode.value];//获取地区父级并去重
        loadAreaNode(mapRegonArr.value, function (error, areaNode) {// eslint-disable-line no-unused-vars
          if (error) {
              return;
          }
        });
      }
      else{
        curChildRegionItemData.value=null;
      }
    }

    //获取当前时间地区指标项值
    let curRegionItemData=reactive([
      {id:'20002709',name:'GDP',icon:'ec-icon-gdp',value:'-',unit:'',isForecast:'',isSel:false},
      {id:'20002724',name:'人均GDP',icon:'ec-icon-avg-gdp',value:'-',unit:'',isForecast:'',isSel:false},//人均GDP
      {id:'20010507',name:'固投增速',icon:'ec-icon-gudingzichan',value:'-',unit:'',isForecast:'',isSel:false},//固定资产投资
      {id:'20000848',name:'社消总额',icon:'ec-icon-xiaofei',value:'-',unit:'',isForecast:'',isSel:false},//社会消费品零售总额
      {id:'20000009',name:'财政收入',icon:'ec-icon-finance',value:'-',unit:'',isForecast:'',isSel:false},//财政收入
      {id:'20000922',name:'人均可支配收入',icon:'ec-icon-shouru',value:'-',unit:'',isForecast:'',isSel:false},//人均收入
      {id:'20002696',name:'进出口总额',icon:'ec-icon-fenxi',value:'-',unit:'',isForecast:'',isSel:false},//进出口总额
      // {id:'20009796',name:'城镇化率',value:'-',unit:'',isForecast:'',isSel:false},//城镇化率
      {id:'20000998',name:'年末常住人口',icon:'ec-icon-pepole',value:'-',unit:'',isForecast:'',isSel:false},//年末常住人口
    ]);
    //获取地区当前指标数据
    const getCurRegionItemDataFun= async()=>{
      curRegionItemData.forEach(t=>{t.value='-',t.isForecast='',t.isSel=false})
      let count=0;
      for(let item of curRegionItemData){
        const params={
          regionCode:regionCode.value,
          date:currentDate.value,
          itemId: item.id.toString(),
          isForecast:true
        }
        //console.log(params);
        let res= await  getCurRegionItemData(params);//当前时间地区指标项值
        //console.log(res);
        if(res.code==20000 && res.data){
          if(count==0){
            item.isSel=true;
          }
          item.value=res.data.itemValue?res.data.itemValue:'-';
          item.unit=res.data.itemUnit;
          item.isForecast= res.data.isForecast?"E":"";
          item.itemIncrement=res.data.itemIncrement?res.data.itemIncrement:'-';
          item.speed=res.data.speed?res.data.speed:'-'
          item.ranking=res.data.ranking?res.data.ranking:'-';
          count++;
        }
        else{
          item.value='-';
          item.isForecast= "";
          item.speed='-'
          item.ranking='-'
        }
      }
      if(!curRegionItemData.find(t=>t.isSel==true)){
        curRegionItemData[0].isSel=true
      }
      // const params={
      //   regionCode:regionCode.value,
      //   date:currentDate.value,
      //   itemId: curRegionItemData.map(t=>{return t.id}).toString(),
      //   isForecast:true
      // }
      // //console.log(params);
      // const result= await getCurRegionItemData(params);//当前时间地区指标项值
      // //console.log(result);
      // if( result.code==20000){
      //   curRegionItemData.forEach((item)=>{
      //     var filterAry=result.data.filter(t=>t.itemId==item.id);
      //     if(filterAry && filterAry.length>0)
      //     {
      //       item.value=filterAry[0].itemValue==null?'-':filterAry[0].itemValue;
      //       item.unit=filterAry[0].itemUnit;
      //       item.isForecast=filterAry[0].isForecast?"E":"";
      //     }
      //   });
      // }
      // else{
      //   curRegionItemData.value=null;
      // }
    }
    //获取地区综合得分
    const getRegionScoreFun=async()=>{
      let regionline='';
      let regionlineName='';
      let orderName='';
      if(regionLevel.value=="1"){
        regionline='900000';
        regionlineName='省级平均';
        orderName='省级地区排名'
      }
      else if(regionAttribute.value.indexOf('一线城市')>=0 || regionAttribute.value.indexOf('新一线城市')>=0){
        regionline='900001';
        regionlineName='一线平均';
        orderName='一线城市排名'
      }
      else if(regionAttribute.value.indexOf('二线城市')>=0 ){
        regionline='900002';
        regionlineName='二线平均';
        orderName='二线城市排名'
      }
      else if(regionAttribute.value.indexOf('三线城市')>=0 ){
        regionline='900003';
        regionlineName='三线平均';
        orderName='三线城市排名'
      }
      else if(regionAttribute.value.indexOf('四线城市')>=0 ){
        regionline='900004';
        regionlineName='四线平均';
        orderName='四线城市排名'
      }
      else if(regionAttribute.value.indexOf('五线城市')>=0 ){
        regionline='900005';
        regionlineName='五线平均';
        orderName='五线城市排名'
      }
      //获取指标信息
      let itemIds='20010525,20010526,20010527,20010528,20010529,20010530';
      let resItem= await getItemList({itemId:itemIds,pageIndex:1,pageSize:1});
      if(resItem.code==20000){
        resItem.data.dataList
        const params={
          regionCode:regionCode.value+','+ regionline,
          itemId: itemIds,
        }
        //console.log(params);
       
        let res= await getRegionEcoVitality(params);
        console.log(res);
        if(res.code==20000){//当前时间地区指标项值
          //经济活力得分
          let obj=res.data.find(t=>t.itemId=='20010530');
          if(obj){
            regionScore.value="得分("+parseTime(new Date(obj.date),'{y}')+'):'+obj.itemValue;
            regionOrder.value= orderName+':'+ obj.sort+'/'+obj.regionNum;
          }
          
          //雷达图
          let filterAry=[];
          let filterAry1=[];
          resItem.data.dataList.forEach(t=>{
            if(t.itemId=='20010530')return;
            let obj=res.data.find(el=>el.regionCode==regionCode.value && el.itemId==t.itemId)
            filterAry.push({
              itemName:t.itemName,
              itemValue:obj?obj.itemValue:'-'
            });
            let obj1=res.data.find(el=>el.regionCode==regionline && el.itemId==t.itemId)
            filterAry1.push({
              itemName:t.itemName,
              itemValue:obj1?obj1.itemValue:'-'
            });
          })
          nextTick(()=>{
            var chartDom = document.getElementById('chart_2');
            var myChart = echarts.getInstanceByDom(chartDom);
            if(myChart){
                myChart.clear();
            }
            else{
                myChart = echarts.init(chartDom);
            }
            let option = {
              legend: {
                type: 'scroll',
                //orient: 'vertical',
                //right: 10,
                bottom: 5,
              },
              radar: [
                {
                  indicator: filterAry.map(t=>{
                    return {text:t.itemName.replace('经济活力_',''),max:100,val:t.itemValue}
                  }) ,
                  center: ['50%', '50%'],
                  radius: 75,
                  axisName: {
                    // color: '#fff',
                    // backgroundColor: '#666',
                    // borderRadius: 3,
                    // padding: [3, 5],
                    formatter: function (value, indicator) {
                      return '{a|'+value+'}\n{per|'+indicator.val+'}  '
                    },
                  
                    rich: {
                      a: {
                        color: '#6E7079',
                        lineHeight: 22,
                        align: 'center'
                      },
                      per: {
                        color: '#fff',
                        backgroundColor: '#002FA7',
                        padding: [4, 5,1,5],
                        borderRadius: 4
                      }
                    }
                  }
                }
              ],
              series: [
                {
                  type: 'radar',
                  data: [
                    {
                      value: filterAry.map(t=>{
                        return t.itemValue
                      }) ,
                      name: regionName.value,
                      areaStyle: {
                        color: hexToRGBA('#002FA7',0.2)
                      }
                    }
                  ]
                },
                {
                  type: 'radar',
                  symbol: 'rect',
                  symbolSize: 6,
                  lineStyle: {
                    type: 'dashed'
                  },
                  // itemStyle:{
                  //   color:'rgba(145,204,117,1)'
                  //   },
                  label: {
                    show: true,
                    //color:'rgba(145,204,117,1)',
                    formatter: function (params) {
                      return params.value;
                    }
                  },
                  data: [
                    {
                      value: filterAry1.map(t=>{
                        return t.itemValue
                      }) ,
                      name: regionlineName,
                    }
                  ]
                }
              ]
            };
            myChart.setOption(option);
            echartsAry.value.push(myChart);
          })
        }
      }
    }
    //经济活力的各项数据
    let regionGlobalAnalysisData=ref([])//地区综合分析数据列表
    let ecoVitality_itemName=ref();//经济活力的各项名称
    const getRegionEcoVitalityFun=async()=>{
      //获取指标信息
      let itemIds=[];
      if(selCard.value==3){//经济
        itemIds=['20010511,20010512,20010513,20010514','20010525'];
      }
      else if(selCard.value==4){//财政
        itemIds=['20010515,20010516,20010517','20010526'];
      }
      else if(selCard.value==5){//民生
        itemIds=['20010518,20010519,20010520','20010527'];
      }
      else if(selCard.value==6){//营商环境
        itemIds=['20010521,20010522','20010528'];
      }
      else if(selCard.value==7){//消费市场
        itemIds=['20010523,20010524','20010529'];
      }
      let resItem= await getItemList({itemId:itemIds.join(','),pageIndex:1,pageSize:1});
      if(resItem.code==20000){
        resItem.data.dataList
        const params={
          regionCode:regionCode.value,
          itemId: itemIds.join(','),
        }
        //console.log(params);
        regionGlobalAnalysisData.value=[];
        let res= await getRegionEcoVitality(params);
        //console.log(res);
        if(res.code==20000){//当前时间地区指标项值
          ecoVitality_itemName.value=resItem.data.dataList.find(t=>t.itemId==itemIds[1]).itemName;
          regionGlobalAnalysisData.value=resItem.data.dataList.filter(t=>itemIds.join(',').indexOf(t.itemId)>=0).map(t=>{
            let obj=res.data.find(el=>el.itemId==t.itemId && el.regionCode== regionCode.value);
            if(t.itemId==itemIds[1]){
              return {
                itemId:t.itemId,
                itemName:obj? t.itemName.substr(t.itemName.indexOf('_')+1)+(',3,4,5,'.indexOf(','+selCard.value+',')>=0?'评价':'')+'('+parseTime(new Date(obj.date),'{y}年')+')：'+obj.itemValue:t.itemName.substr(t.itemName.indexOf('_')+1),
                itemValue:obj?obj.itemValue:'',
                weight:obj?(obj.weight*100)+'%':'',
                sourceValue:obj?obj.sourceValue:''
              }
            }
            return {
              itemName:t.itemName.substr(t.itemName.indexOf('_')+1),
              itemValue:obj?obj.itemValue:'',
              weight:obj?(obj.weight*100)+'%':'',
              sourceValue:obj?obj.sourceValue:''
            }
          });
        }
      }
    }
    //单击指标项
    const selItemFun=async (id)=>{
      start();
      //const loadingInstance = ElLoading.service({ fullscreen: true })
      //去掉选中
      curRegionItemData.filter(t=>{return t.isSel==true})[0].isSel=false;
      //重新选中
      curRegionItemData.filter(t=>{return t.id==id})[0].isSel=true;
      //地图显示
      await getLevelReginCurItemDataFun();
      //当前地区同级排名
      await getRegionItemSortFun();
      //当前地区同级地区增速排名
      //await getRegionSpeedSortFun();
      close();//关闭加载
      //loadingInstance.close();
      //记录用户操作日志
      addUserLog(router.currentRoute.value.name,router.currentRoute.value.fullPath,'选指标项',JSON.stringify({itemId:id}));
    }
    //获取当前地区的同级地区的排名列表
    let regionSortList=ref([]);
    const getRegionItemSortFun= async()=>{
      
      const params={
        regionCode:regionCode.value,
        date:currentDate.value,
        itemId:curRegionItemData.find(t=>t.isSel==true)?.id ,
        regionRange:'',
      }
      //console.log(params);
      const result= await getRegionItemSort(params);//当前时间地区指标项排名
      //console.log(result);
      if( result.code==20000){
        regionSortList.value=result.data.dataList
      }
    }
    
    //获取同级地区的指标项增速排名
    //let regionSpeedList=ref([]);
    let dateIntervalNum=10;//时间间隔数
    let selRegionCode=ref('')//选中地区编号
    let selRegionName=ref('')//选中地区名称

    // const getRegionSpeedSortFun=async()=>{
    //   const params={
    //     regionCode:regionCode.value,
    //     date:currentDate.value,
    //     itemId: curRegionItemData.find(t=>t.isSel)?.id,
    //     dateIntervalNum:dateIntervalNum
    //   }
    //   //console.log(params);
    //   const result= await getRegionSpeedSort(params);//当前时间地区指标项排名
    //   //console.log(result);
    //   if( result.code==20000){
    //     regionSpeedList.value=result.data.filter((item,index)=>index<5 || item.regionCode==regionCode.value);
    //   }
    // }
    const tableRowClassName = (obj) => {
      //console.log(obj.row);
      if (obj.row.regionCode === regionCode.value) {
        return 'highlight'
      } 
      return ''
    }
    const ecovitalityTableRowClassName = (obj) => {
      //console.log(obj.row);
      if (obj.row.itemId) {
        return 'highlight'
      } 
      return ''
    }
    //table,合并列
    const arraySpanMethod = (param) => {
      const {row,column,rowIndex,columnIndex} = param
      console.log(row)
      console.log(column)
      console.log(rowIndex)
      if (row.itemId) {
        if (columnIndex === 0) {
          return [1, 4]
        } else  {
          return [0, 0]
        }
        
      }
    }
    
    //选中同级地区指标增速排名的行，获取选中地区的历年变化
    const getRegionItemListFun=async()=>{
      const params={
        regionCode:selRegionCode.value,
        itemId:JSON.stringify( [{"itemId":curRegionItemData.find(t=>t.isSel==true)?.id,"objId":""}]) ,
      }
      //console.log(params);
      const result= await getRegionItemLine(params);//当前时间地区指标项排名
      //console.log(result);
      if( result.code==20000){
        if(result.data.item){
          loadingShow.value=true;
          //计算增速
          let speedList=[];
          let ary=result.data.item[0].itemValue;
          ary.forEach((t,index)=>{
            if(index>0){
              if( t && ary[index-1] && ary[index-1]!=0){
                speedList[index]=((t-ary[index-1])/ary[index-1]*100);
              }
              else{
                speedList[index]=null;
              }
            } 
          })
          speedList[0]=null;
          //获取响应的时间段
          let index= result.data.date.findIndex(v => v === currentDate.value);
          //console.log(index);
          let dateAry=[];
          let itemValueAry=[];
          let itemSpeedValueAry=[]; 
          if(index-dateIntervalNum+1<0){
            dateAry=result.data.date.slice(0,index+1);
            itemValueAry=ary.slice(0,index+1);
            itemSpeedValueAry=speedList.slice(0,index+1);
            let date=new Date(dateAry[0]);
            let length=0-(index-dateIntervalNum+1);
            for(let i=1;i<=length;i++){
              //指标更新周期:年
              dateAry.unshift((date.getFullYear()-i)+'-01-01');
              itemValueAry.unshift(null);
              itemSpeedValueAry.unshift(null);
            }
          }
          else{
            dateAry=result.data.date.slice(index-dateIntervalNum+1,index+1);
            itemValueAry=ary.slice(index-dateIntervalNum+1,index+1);
            itemSpeedValueAry=speedList.slice(index-dateIntervalNum+1,index+1);
          }
          nextTick(()=>{
            var chartDom = document.getElementById('chart_1');
            var myChart = echarts.init(chartDom);
            var option = {
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'cross',
                  crossStyle: {
                    color: '#999'
                  }
                }
              },
              // toolbox: {
              //   feature: {
              //     dataView: { show: true, readOnly: false },
              //     magicType: { show: true, type: ['line', 'bar'] },
              //     restore: { show: true },
              //     saveAsImage: { show: true }
              //   }
              // },
              // legend: {
              //   data: ['Evaporation', 'Precipitation', 'Temperature']
              // },
              grid:{
                top:40,
                left:20,
                right:20,
                bottom:10,
                containLabel:true
              },
              xAxis: [
                {
                  type: 'category',
                  data: dateAry,
                  axisPointer: {
                    type: 'shadow'
                  },
                  axisLabel:{
                    formatter: function (params) {
                            return parseTimeOfCycle(params,result.data.itemCycle.toString())
                          }
                  }
                }
              ],
              yAxis: [
                {
                  type: 'value',
                  name: '实际值('+result.data.item[0].itemUnit+')',
                  alignTicks:true,
                  // min: 0,
                  // max: 250,
                  // interval: 50,
                  // axisLabel: {
                  //   formatter: '{value}'+result.data.item[0].itemUnit
                  // }
                },
                {
                  type: 'value',
                  name: '增速(%)',
                  alignTicks:true,
                  // min: 0,
                  // max: 25,
                  // interval: 5,
                  axisLabel: {
                    formatter: '{value} %'
                  }
                }
              ],
              series: [
                {
                  name: result.data.item[0].itemName,
                  type: 'bar',
                  barWidth:'40%',
                  tooltip: {
                    valueFormatter: function (value) {
                      return (value?value:'-') + result.data.item[0].itemUnit;
                    }
                  },
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: '#83bff6' },
                      { offset: 0.5, color: '#002FA7' },
                      { offset: 1, color: '#002FA7' }
                    ])
                  },
                  emphasis: {
                    itemStyle: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: '#83bff6' },
                        { offset: 0.7, color: '#2378f7' },
                        { offset: 1, color: '#2378f7' },
                        
                      ])
                    }
                  },
                  data: itemValueAry
                },
               
                {
                  name: '增速',
                  type: 'line',
                  yAxisIndex: 1,
                  smooth: true,
                  itemStyle: {
                    color: '#fac858'
                  },
                  tooltip: {
                    valueFormatter: function (value) {
                      return (value?value.toFixed(2):'-') + ' %';
                    }
                  },
                  data: itemSpeedValueAry,
                  // markPoint: {
                  //   data: [
                  //     { type: 'max', name: 'Max' },
                  //     { type: 'min', name: 'Min' }
                  //   ]
                  // },
                  markLine: {
                    data: [{
                        name: '平均增速',
                        type: 'average',
                        label:{
                          position : 'insideEndTop'
                        } 
                    }]
                  }
                }
              ]
            };

            option && myChart.setOption(option);
            echartsAry.value.push(myChart);
          })
        }
        else{
          loadingShow.value=false;
        }
      }
      //记录用户操作日志
      addUserLog(router.currentRoute.value.name,router.currentRoute.value.fullPath,'查看历史趋势',JSON.stringify({regionCode:selRegionCode.value,itemId:curRegionItemData.find(t=>t.isSel==true)?.id}));
    }
    
    let isVisible=ref(false);
    let loadingShow=ref(false);
    const regionSpeedListRowClick=async (row,col,event)=>{
      isVisible.value=true;
      loadingShow.value=true;
      selRegionCode.value=row.regionCode;
      selRegionName.value=row.regionName;
      //await  getRegionItemListFun(row.regionCode);
      console.log(col);
      console.log(event);
    }

     //选择时间
    const selectDate=async (date)=>{
      currentDate.value=date;
      start();
      //获取当前时间地区指标项值
      await getCurRegionItemDataFun();
      //地图显示
      await getLevelReginCurItemDataFun();
      //当前地区同级排名
      await getRegionItemSortFun();
      //当前地区同级地区增速排名
      //await getRegionSpeedSortFun();
      close();//关闭加载
      //记录用户操作日志
      addUserLog(router.currentRoute.value.name,router.currentRoute.value.fullPath,'选日期',JSON.stringify({date:date}));
    }
    //地区经济活力——选中卡片事件
    let selCard =ref(2);
    const selCardFun=(id)=>{
      selCard.value=id;
       if(id>2){
        getRegionEcoVitalityFun();
      }
    }
    //获取产业链列表
    let industryChainNum=ref(0);//产业链的数量
    let industryChainList=ref([]);
    const getIndustryChainListFun=async()=>{
      let params={
        regionCode:regionCode.value,
        pageIndex:1,
        pageSize:1
      };
      let res= await getIndustryChainInfoList(params);
      //console.log(res);
      if(res.code==20000){
        industryChainNum.value=res.data.totalCount;
        industryChainList.value=res.data.dataList.sort(function (a, b) {
            let n=0;
            if(a.isShow!=null) n=a.isShow;
            let n1=0;
            if(b.isShow!=null) n1=b.isShow;
            return n1 - n;
        });
      }
    }
    //选择产业链跳转
    const selChainFun=async(info)=>{
      if(await getUserRightFun('industryChainInfo',0)){
        if(info.isShow==1){
          const { href } = router.resolve({
                    path: '/industryChain/info',
                    query:{id:info.id}
                  });
          //console.log(href)
          window.open(href, "_blank");
        }
        else{
          return false;
        }
      }
    }
    //自适应
    let listener=()=> {
      windowHeight.value =document.documentElement.clientHeight;// 可视区的高度
      echartsAry.value.forEach(element => {
        element.resize();
      });
    }
    window.addEventListener('resize', listener)//监听resize事件
    onMounted(()=>{
    })
    onBeforeUnmount(()=>{//销毁之前
      //clearInterval(timer);
      window.removeEventListener('resize',listener);
        echartsAry.value.forEach(element => {
          if (element) {
            element.clear(); //清空图表
            element.dispose(); //释放图表组件
            element = null;
          }
        }); 
    })
    return{
      isRight,
      windowHeight,
      //dom对象
      //header,
      regionName,
      regionAttribute,
      regionDesc,
      mapMarker,
      regionCode,
      regionLevel,
      currentDate,
      dateList,
      selectDate,
      curRegionItemData,
      isShowEcoVitality,
      isShowIndustryChain,
      regionScore,
      regionOrder,
      ecoVitality_itemName,
      regionGlobalAnalysisData,
      ecovitalityTableRowClassName,
      arraySpanMethod,
      selItemFun,
      colorLevel,
      colors,
      regionSortList,
      tableRowClassName,
      //regionSpeedList,
      regionSpeedListRowClick,
      isVisible,
      loadingShow,
      getRegionItemListFun,
      selRegionName,
      PrefixInteger,
      listener,
      selCard,
      selCardFun,
      industryChainNum,
      industryChainList,
      selChainFun
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
  #app {
      height:calc(100% + 40px) !important;
  }
  #mapContainer{
    position: fixed;
    height: 100%;
    width: 100%;
    top:35px;
    left: 0;
  }
  .time-title{
    background: orange;
    padding: 5px;
    width: 100%;
    display: inline-block;
    text-align: center;
    color: #000;
    font-weight: 600;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .time-content{
    width:100%;
    background: #fff;
    line-height:60px;
    height: 60px;
    color:$specialtext-color;
    text-align: center;
    cursor: pointer;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    font-weight: 700;
  }
  .box{
    background: rgba(255,255,255,1);
    padding:10px;
    box-shadow: 0 30px 30px 0 rgb(26 37 69 / 8%);
    border-radius: 12px;
  }
  
  .card-f{
    position:absolute;
    bottom: 20px;
    right: 20px;
    left: 20px;
    background-color: rgba(255,255,255, 1);
    border-radius: 12px;
    box-shadow: 0 30px 30px 0 rgb(26 37 69 / 8%)
  }
  .para-title {
    line-height: 50px;
    font-size: 1.4rem;
    font-weight: 400;
    position:absolute;
    color: $specialtext-color;
    padding-left: 10px;
    .para-title-text{
      font-weight: 600;
    }
  }
  .tipMarker.top {
      transform: translate(-50%,-110%);
  }
  .tipMarker {
      background-color: rgba($themeColor, 0.8);
      border: 1px solid rgba($themeColor, 0.3);
      border-radius: 5px;
      padding: 6px 10px;
      font-size: 14px;
      color:#fff;
      white-space: nowrap;
      display: inline-block;
  }
.col-d{
  background: rgba(255,255,255,1);
  border:2px solid transparent;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 8%);
  border-radius: 12px;
  padding: 6px 10px;
  position:relative;
  overflow:hidden;
  cursor: pointer;
  min-width: 100px;
  width:200px;
  margin: 0 10px;
  .name{
    // letter-spacing: 1.5px;
    font-weight: bold;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    color: $specialtext-color;;
  }
  p{
    margin: 0;
    font-size: 26px;
    font-weight: 100;
    text-align: center;
    // font-family: pangmen;
  }
  strong{
    font-size: 20px;
    font-weight: 300;
  }
  .item-speed{
    padding-left:3px;
    border-radius: 4px;
    &.up{
      background:rgba($red,0.1);
      color:$red;
    }
    &.down{
      background:rgba($green,0.1);
      color:$green;
    }
  } 
  // .unit{
  //   font-size: 12px;
  //   color: #aaa;
  // }
  &:hover{
    background-color: rgba(227, 235, 255,1);
  }
}
.col-d.selcol{
  background-color: rgba(227, 235, 255,1);
  border:2px solid $themeColor;
  // .name{
  //   color: #fff;
  // }
}
.ul-cl{
  position: absolute;
  left:  20px;
  // margin-left: 20px;
  bottom: 90px;
  background: #fff;
  padding: 10px 20px;
  border:1px solid $bordercolor;
  border-radius: 4px;
  li{
    line-height: 25px;
  .span-cl{
    width: 12px;
    height:12px;
    margin-right: 6px;
    border-radius: 2px;
    border:1px solid;
    vertical-align: middle;
    display: inline-block;
    opacity: 0.7;
  }
  }
}
.txt{
  color: $specialtext-color;
  font-weight: 600;
  display: inline-block;
  width: 100%;
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
}
::v-deep .el-table th > .cell {
  font-size: 12px;
  font-weight: 100;
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  cursor: pointer;
}
.eco-fixed{
  color: $themeColor;
   box-shadow:0 2px 24px 0 rgb(0 0 0 / 8%);
   border-radius: 4px;
   background:#fff;
   display:inline-block;
   padding:5px;
   cursor: pointer;
   &:hover{
     color:#fff;
     background:$themeColor;
   }
}
.card{
  height: 38px;
  width: 38px;
  text-align: center;
  cursor: pointer;
  border: 1px solid $bordercolor;
  border-left:none;
  border-bottom:none;
  display: inline-block;
  line-height: 38px;
  padding: 0 10px;
  border-image: linear-gradient(90deg, transparent , $bordercolor, rgba($bordercolor,0.3)) 1 1 1 1;
  &:hover{
    background:rgba($themeColor,0.2);
  }
  &.active{
    background:$themeColor;
    color:#fff;
  }
}
.box .card:last-child{
  border-bottom:1px solid $bordercolor;
}

.card-info{
  width:341px;
  height:256px;
  position: relative;
  margin:0 10px 10px 10px;
}
.mx-1{
  margin:0 5px 5px 0;
  background-color: rgba($themeColor,0.5);
  border:1px solid #aaa;
  color: #fff;
}
.chain-tab {
    height: 60px;
    padding: 0 10px;
    background: #fff;
    cursor: pointer;
}
.chain-tab-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    border-bottom: 1px solid #f6f8fa;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    &:hover{
      background-color: rgba($themeColor,0.1);
    }
}
.chain-tab-item-title {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    margin-bottom: 6px;
}
.chain-tab-item-subtilte {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #999999;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}
.chain-tab-item-subtilte a {
    display: block;
    margin: 0 8px 0 0;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    color: #999999;
    max-width: 118px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.chain-tab-item-arrow {
    position: absolute;
    right: 0;
    top: 23px;
    color: #ccd6e5;
}
.chain-tab-main {
    width: 360px;
    display: none;
    position: absolute;
    left: 390px;
    top: 0;
    bottom: 0;
    border-left: 1px solid #f6f8fa;
    z-index: 99;
    background: #fff;
    border-radius: 0 12px 12px 0;
    -webkit-box-shadow: 16px 0px 16px 1px rgb(0 0 0 / 6%);
    box-shadow: 16px 0px 16px 1px rgb(0 0 0 / 6%);
    padding-top: 16px;
    overflow: auto;
}
.chain-tab-main-title {
    margin-bottom: 10px;
    font-weight: 900;
    height: 20px;
    letter-spacing: 0px;
}
.chain-tab-main-list {
    -webkit-box-flex: 1;
    -ms-flex: 1 1;
    flex: 1 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.chain-tab-main-list-item {
    letter-spacing: 0px;
    margin-right: 16px;
    margin-bottom: 6px;
    display: block;
    cursor: pointer;
}
.chain-num{
  position:absolute;
  right:30px;
  strong{
    color: $themeColor;
  }
}
.chain-fold{
  position: absolute;
  top:12px;
  right:10px;
  cursor: pointer;
}
.mx-2 {
  margin: 0.25rem;
}

</style>
